import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Master from '../containers/signupflow/master';
import BackgroundImage from '../images/headinclouds.jpg';

const SignUp = () => (
  <Layout>
    <SEO title="Sign Up" />
    <div className="hero container bg-cover bg-top h-screen py-16"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}>
      <div className ="container lg:max-w-md max-w-xs">
        <Master/>
      </div>

    </div>
  </Layout>
);

export default SignUp;
