import React from "react"
import PropTypes from "prop-types"

const WhatWeDo = (props) =>
  {
    const stepname = "whatwedo"
    if (props.currentStep !== stepname) {
      return null
    }
      return(
        <>
          <h2 className ="text-xl font-semibold text-gray-700 text-center">
            Great to meet you, {props.firstname}!
          </h2>
          <div className="what we do mx-3 my-1">
              <p>
              We&apos;re going to help you make progress to a better self by focusing 
              on recurring activities that will make progress towards your goal.  
              </p>
              <p className="italic my-3">
              For example, we help many people track how many times they&apos;ve worked
              out in a week versus their goal number (1, 3, 7, etc.).
              </p>
              <p className="font-semibold">Here&apos;s our approach:</p>
              <ul className = "list-disc list-inside ml-2">
                <li  className="mb-2">We provide an app to help you track your efforts and encourage
                  you to both take action and log your activity.
                </li >
                <li className="mb-2">We&apos;re focused on positive progress for you.  That means that
                we&apos;ll remind you of your wins (big and small) and when you fall down
                you&apos;ll only get encouragement from us.  
                </li>
                <li className="mb-2">There&apos; great research out there about human psychology
                  and we&apos;re going to put that to use for you.  It&apos;s built into our
                  app AND we&apos;ll distill it down into useful life hacks that will
                  help you continue making progress on your goal.
                </li>
              </ul> 
              <br/>
              <h2 className ="text-xl font-semibold text-gray-700 text-center">
                Sound Good?
              </h2>
          </div>
        </>
      )
  }

export default WhatWeDo

WhatWeDo.propTypes = {
    currentStep: PropTypes.string,
    firstname: PropTypes.string
  }