import React from 'react';
import {Link} from 'gatsby';
import FirstName from './firstname';
import WhatWeDo from './whatwewilldo';

/**
 * This is the master component that manages state of the form.
 */
export default class Master extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      steps: ['firstname', 'whatwedo', 'activity',
        'reason', 'price', 'account'],
      currentStep: 'firstname',
      stepNum: 0,
      firstname: '',

    };

    /**
     * bindings
     */
    this.handleChange = this.handleChange.bind(this);
    this._next = this._next.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this._previous = this._previous.bind(this)
  }

  /**
   * handle changes from the input fields
   * @param {*} event
   * */
  handleChange( event ) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * submitting the form
   * @param {*} event
   */
  handleSubmit( event ) {
    event.preventDefault( );
    console.log( 'I submitted' );
  }

  /**
   * advances the state machine to the next step
   */
  _next( ) {
    const stepCount = this.state.steps.length;
    const steps = this.state.steps;
    let stepNum = this.state.stepNum;
    stepNum = stepNum >= (stepCount-1)? stepNum: stepNum+1;
    this.setState({
      stepNum: stepNum,
      currentStep: steps[stepNum],
    });
  }

  /**
   * render also holds conditional logic
   * @return {node} the blob of form stuff.
   */
  render() {
    const stepNum = this.state.stepNum;
    console.log([this.state, stepNum]);
    let nextButton;
    let prevButton;
    let SubmitButton;
    const stepCount = this.state.steps.length;

    if ( stepNum < stepCount-1 ) {
      nextButton =
            <button
              className="btn-cta py-2 px-6"
              type="button"
              onClick = {this._next}>
              Next
            </button>;
    }
    if ( stepNum > 0 ) {
      prevButton =
            <button
              className="underline text-blue-500"
              type = "button"
            >
              Back
            </button>;
    }

    if ( stepNum > 0 ) {
      prevButton =
            <button
              className="underline text-blue-500"
              type = "button"
            >
              Back
            </button>;
    }


    return (
      <div className="bg-white rounded border-teal-300
      border-4 shadow flex justify-center py-3">
        <form className="lg:max-w-md max-w-xs"
          onSubmit={this.handleSubmit}>

          <FirstName
            currentStep = {this.state.currentStep}
            handleChange = {this.handleChange}
            field = {this.state.firstname}
            onKeyPress = {this.handleEnter}
          />
          <WhatWeDo
            currentStep = {this.state.currentStep}
            firstname = {this.state.firstname}
          />
          <div className="flex justify-center my-4">
            {nextButton}
          </div>
        </form>
      </div>

    );
  }
}
