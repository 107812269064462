import React from 'react';
import PropTypes from 'prop-types';

const FirstName = (props) => {
  const stepname = 'firstname';
  if (props.currentStep !== stepname) {
    return null;
  }
  return (
        <>
          <h2 className ="text-xl font-semibold text-gray-700 text-center">
              First, Let&#39;s Get To Know You...
          </h2>
          <div className="Yourname mx-3">
            <label
              className = "block text-gray-700 text my-2"
              htmlFor = {stepname}
            >
                What&#39;s Your First Name?
            </label>
            <input
              className = "form-control border-b appearance-none w-full \
                            text-gray-700 leading-tight focus:outline-none \
                            py-1 px-1 text-lg"
              id = {stepname}
              name = {stepname}
              type = "text"
              placeholder = "My name is..."
              value = {props.field}
              onChange = {props.handleChange}
              onKeyPress = {props.handleEnter}
            />
          </div>
        </>
  );
};

export default FirstName;

FirstName.propTypes = {
  field: PropTypes.string,
  currentStep: PropTypes.string,
  handleChange: PropTypes.func,
  handleEnter: PropTypes.func,

};
